<template>
  <div>
    <div v-for="column of columns" :key="column.id" class="tw-my-10">
      <!-- We use identifier to reset table filters when init results the results -->
      <DataTable
        v-model="selectedLeads[column.id]"
        :key="identifier"
        :loading="loading[column.id]"
        :headers="HEADERS"
        :can-search="true"
        :min-search-chars="3"
        :can-select="true"
        :all-record-ids="leadIds[column.id]"
        v-bind="leads[column.id]"
        @changePage="fetchLeads(column, $event)"
        @filter="loadDataForColumn(column, $event)"
      >
        <template #toolbar>
          <div class="tw-my-2 tw-flex tw-flex-wrap tw-items-center tw-justify-between">
            <h3>{{ column.title }}</h3>
            <div v-if="breakingLeadsForColumnId === column.id">
              <i class="fa fa-spinner fa-spin tw-mr-2" />
              <span class="font-bold">Leads worden gebreakt ... </span>
            </div>
            <button
              v-else
              type="button"
              title="Break geselecteerde leads"
              :disabled="!(selectedLeads[column.id] && selectedLeads[column.id].length)"
              class="action tw-bg-danger"
              @click="showBreakModal(column)"
            >
              <i class="fa fa-trash" /> Break geselecteerde leads
            </button>
          </div>
        </template>

        <template #item-id="{ item }">
          <router-link :to="{ name: 'LeadDetails',  params: { id: item.id }}" @click.native="saveLeadIds(column.id)">
            {{ item.entity_data.address }}
          </router-link>
        </template>

        <template #item-language="{ item }">
          <span class="tw-uppercase">
            {{ item.language }}
          </span>
        </template>

        <template #item-candidate="{ item }">
          <div class="tw-flex tw-gap-x-2">
            <span v-if="item.candidate">{{ item.candidate.name }}</span>
            <ContactNotes v-if="item.candidate" :object="item.candidate" />
          </div>
        </template>

        <template #item-contact_score="{ item }">
          <ContactScore
            v-if="item.candidate && item.candidate.contact_score"
            :score="item.candidate.contact_score"
            :contact-id="item.candidate.id"
          />
          <span v-else>-</span>
        </template>

        <template #item-last_activity="{ item }">
          <div v-if="item.last_activity_name">
            {{ item.last_activity_name }}: {{ item.last_activity_date | date-day }}
          </div>
        </template>

        <template #item-next_activity="{ item }">
          <span v-if="item.next_activity_name">
            {{ item.next_activity_name }}: {{ item.next_activity_date | date-day }}
          </span>
        </template>

        <template #item-actions="{ item }">
          <div class="tw-flex">
            <template v-if="item.next_activity_name">
              <button
                title="Bewerken"
                class="action tw-bg-success hover:tw-bg-darken-success"
                @click="editActivity(item.next_activity_id)"
              >
                Bewerken
              </button>
              <button
                title="Afpunten"
                class="action tw-bg-warning"
                @click="selectLead('finish', item, item.next_activity_id)"
              >
                Afpunten
              </button>
            </template>
            <button
              v-else
              title="Nieuw"
              class="action tw-bg-primary"
              @click="selectLead('new', item)"
            >
              Nieuw
            </button>
          </div>
        </template>
      </DataTable>
    </div>

    <ActivityFeedbackModal
      ref="feedbackModal"
      :activity="selectedActivity"
      :lead="selectedLead"
      :showBreakButton="true"
      :show-checkbox="feedbackModalCheckbox"
      :title="feedbackModalTitle"
      @gotoLead="gotoLead"
      @activity-finished="activityFinished"
    />

    <ActivityFeedbackModal
      ref="breakModal"
      title="Geselecteerde leads breaken"
      :lead="null"
      :multi-mode="true"
      :show-break-button="true"
      :show-details-button="false"
      :show-finish-button="false"
      :show-dewaele-diy-break-button="false"
      :handle-success-modal="false"
      @finished="breakSelectedLeads"
    />
    <NewActivityModal ref="newActivityModal" :lead="selectedLead" @activity-saved="init" />
    <EditActivityModal ref="editActivityModal" @activity-changed="init" />
    <ActivityCancelModal
      ref="activityCancelModal"
      :activity="selectedActivity"
      @activity-cancelled="init"
    />
    <LeadBreakHmodhFormOpener ref="hmodhOpener" />
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

import DataTable from '@/components/DataTable'
import NewActivityModal from '@/components/sales/NewActivityModal'
import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'
import ContactNotes from '@/components/contacts/contact_render_components/ContactNotes'
import LeadBreakHmodhFormOpener from '@/components/sales/LeadBreakHmodhFormOpener'
import EditActivityModal from '@/components/sales/EditActivityModal'
import ActivityCancelModal from '@/components/sales/ActivityCancelModal'
import ContactScore from '@/components/contacts/ContactScore.vue'

import { errorModal, successModal } from '@/modalMessages'
import { getJobStatus } from '@/services/apiService'
import { getActivity, getLeads, getLeadIds, breakLeads } from '@/services/sales'
import { isFutureIntakeOrVisitActivity } from '@/utils/sales'
import { poll } from '@/utils/helpers'

import { mapState } from 'vuex'

export default {
  name: 'FunnelTableView',
  components: {
    DataTable,
    ContactNotes,
    NewActivityModal,
    ActivityFeedbackModal,
    LeadBreakHmodhFormOpener,
    EditActivityModal,
    ActivityCancelModal,
    ContactScore
  },
  computed: {
    ...mapState('sales', ['followerLeads'])
  },
  data () {
    return {
      identifier: uniqueId('funnel_'),
      loading: {},
      leads: {},
      leadIds: {},
      selectedLeads: {},
      breakLeadsColumn: {},
      feedbackModalTitle: 'Activiteit afpunten',
      feedbackModalCheckbox: true,
      selectedActivity: null,
      selectedLead: null,
      breakingLeadsForColumnId: null
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    query: {
      type: String
    },
    status: {
      type: Number
    }
  },
  constants: {
    HEADERS: [
      { value: 'id', text: 'Lead' },
      { value: 'language', text: 'Taal' },
      { value: 'candidate', text: 'Kandidaat' },
      { value: 'contact_score', text: 'Contactscore' },
      { value: 'last_activity', text: 'Laatste activiteit' },
      { value: 'next_activity', text: 'Volgende activiteit' },
      { value: 'source_name', text: 'Bron' },
      { value: 'actions', text: 'Actie' }
    ]
  },
  watch: {
    columns: {
      immediate: true,
      handler () {
        this.init()
      }
    },
    query () {
      this.init()
    },
    status () {
      this.init()
    }
  },
  methods: {
    // NOTE: We use this.$set in this component a lot, it's to make the nested properties reactive.

    updateSelectedItems (column, selectedRecords) {
      this.$set(this.selectedLeads, column.id, selectedRecords)
    },
    gotoLead (lead) {
      this.$router.push({ name: 'LeadDetails', params: { id: lead.id } })
      this.$refs.feedbackModal.hide()
    },
    showFeedbackModal () {
      this.$refs.feedbackModal.show()
    },
    showNewActivityModal () {
      this.$refs.newActivityModal.show()
    },
    editActivity (activityId) {
      this.$refs.editActivityModal.show(activityId)
    },
    showBreakModal (column) {
      this.breakLeadsColumn = column
      this.$refs.breakModal.show()
    },
    saveLeadIds (columnId) {
      const leadIds = this.leadIds[columnId]
      localStorage.setItem('lead_ids', leadIds)
    },

    init () {
      this.identifier = uniqueId('refreshed_funnel_')
      const promises = this.columns.map(column => this.loadDataForColumn(column))
      return Promise.all(promises)
    },

    loadDataForColumn (column, data = {}) {
      const params = { ...data.params, ...column.params }
      params.query = this.query || data.params?.query
      params.status = this.status
      return Promise.all([
        this.fetchLeads(column, { params }),
        this.fetchLeadIds(column, { params })
      ])
    },
    async fetchLeads (column, payload = {}) {
      try {
        this.$set(this.loading, column.id, true)
        // On the initial page load, the payload will contain a params object
        // When switching pages, the payload contains
        // the next or previous URL that can be used to fetch the data
        if (!('url' in payload)) {
          payload.params.include = this.followerLeads
          payload.params.source = 'dashboard'
        }
        const response = await getLeads(payload)
        this.$set(this.leads, column.id, response.data)
        this.$set(this.loading, column.id, false)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van leads, probeer het opnieuw.')
      }
    },
    async fetchLeadIds (column, payload = {}) {
      try {
        payload.params.include = this.followerLeads
        const response = await getLeadIds(payload)
        this.$set(this.leadIds, column.id, response.data)
        return response
      } catch (error) {
        errorModal('Er is iets misgegaan, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },

    async breakSelectedLeads () {
      try {
        const feedback_internal = this.$refs.breakModal.feedbackInternal || ' '
        const feedback_reporting = this.$refs.breakModal.feedbackReporting || ' '
        const leads = [...this.selectedLeads[this.breakLeadsColumn.id]]
        this.breakingLeadsForColumnId = this.breakLeadsColumn.id
        const response = await breakLeads({ leads, feedback_internal, feedback_reporting })
        await poll(response?.data?.job_id, getJobStatus, 1000)
        successModal('De geselecteerde leads werden gebreakt.')
        await this.loadDataForColumn(this.breakLeadsColumn)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het breaken van de leads, gelieve opnieuw te proberen', true)
      } finally {
        this.breakingLeadsForColumnId = null
      }
    },

    async selectLead (action, lead, activityId) {
      this.selectedLead = lead
      if (activityId) {
        const response = await getActivity(activityId)
        this.selectedActivity = response.data
      }
      if (action === 'finish' && isFutureIntakeOrVisitActivity(this.selectedActivity)) this.$refs.activityCancelModal.show()
      else if (action === 'finish') this.showFeedbackModal()
      else if (action === 'new') this.showNewActivityModal()
    },
    activityFinished (activity, lead) {
      this.selectedLead = lead
      if (!(lead.funnel?.id === 3 && activity.type === 2)) {
        // Only show new activity modal for non-rental funnels
        if (!(lead.status === 2 && activity.type === 115)) this.showNewActivityModal()
      }
      this.$refs.feedbackModal.hide()
      this.$refs.hmodhOpener.trigger(lead.id)
      this.init()
    }
  }
}
</script>
