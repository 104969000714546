<template>
  <div v-if="columns.length" class="tw-mb-8 tw-grid tw-grid-flow-col tw-auto-cols-fr tw-gap-4">
    <div v-for="column of columns" :key="column.id">
      <h3 class="text-center">{{ column.title }}</h3>
      <transition-group v-if="Array.isArray(leads[column.id])" tag="ul" name="fade" class="agile-list">
        <LeadCard
          v-for="lead in leads[column.id]"
          :key="lead.id"
          :lead="lead"
          :show-picture="showPictures"
          @navigation="saveLeadIds(column.id)"
        />
      </transition-group>
      <InfiniteLoading :identifier="identifier" spinner="waveDots" @infinite="loadLeads($event, column)">
        <div slot="no-more"><!-- Empty div to not render anything --></div>
        <template #no-results>Geen leads gevonden</template>
        <template #error="{ trigger }">
          Fout bij laden van leads,
          <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
            probeer het opnieuw.
          </button>
        </template>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

import InfiniteLoading from 'vue-infinite-loading'
import LeadCard from '@/components/sales/LeadCard'

import { getLeads, getLeadIds } from '@/services/sales'
import { mapState } from 'vuex'

export default {
  name: 'FunnelColumnView',
  components: {
    LeadCard,
    InfiniteLoading
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    query: {
      type: String
    },
    status: {
      type: Number
    }
  },
  data () {
    return {
      identifier: uniqueId('funnel_'),
      leads: {},
      leadIds: {},
      nextURLs: {},
      previousURLs: {}
    }
  },
  computed: {
    ...mapState('sales', ['showPictures']),
    ...mapState('sales', ['followerLeads'])
  },
  watch: {
    columns () {
      this.resetInfiniteLoading()
    },
    query () {
      this.resetInfiniteLoading()
    },
    status () {
      this.resetInfiniteLoading()
    }
  },
  created () {
    this.fetchLeadIds()
  },
  methods: {
    saveLeadIds (columnId) {
      const leadIds = this.leadIds[columnId]
      localStorage.setItem('lead_ids', leadIds)
    },
    async fetchLeadIds () {
      const promises = this.columns.map(async (column) => {
        const { params, id } = column
        const payload = {
          params: {
            query: this.query,
            status: this.status,
            include: this.followerLeads,
            ...params
          }
        }
        const response = await getLeadIds(payload)
        this.$set(this.leadIds, id, response.data)
        return response
      })
      return Promise.all(promises)
    },
    async loadLeads ($state, column) {
      try {
        const { params, id } = column
        const url = this.nextURLs[id]
        const payload = { url }

        params.query = this.query
        params.status = this.status

        const include = this.followerLeads

        if (!url) {
          params.include = include
          payload.params = { ...params }
          params.source = 'dashboard'
        }

        const response = await getLeads(payload)
        const { results, next, previous } = response?.data

        this.$set(this.nextURLs, id, next)
        this.$set(this.previousURLs, id, previous)

        if (results?.length) {
          if (this.previousURLs[id]) this.leads[id].push(...results) // Concatenate only when the previous page exists
          else this.$set(this.leads, id, results) // If this is the first page, just set values
          $state.loaded()
        }

        if (!next) $state.complete()
      } catch (error) {
        $state.error()
      }
    },
    resetInfiniteLoading () {
      this.identifier = uniqueId('funnel_')
      this.nextURLs = {}
      this.prevousURLs = {}
      this.leads = {}
      this.leadIds = {}
    }
  }
}
</script>
