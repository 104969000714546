<template functional>
  <li :key="props.lead.id" class="warning-element">
    <router-link :to="{ name: 'LeadDetails', params: { id: props.lead.id } }" @click.native="listeners['navigation']">
      <div class="agile-detail">
        <div class="row">
          <h3 class="col-sm-12">
            {{ props.lead.entity_data.address }}
          </h3>
        </div>
        <div class="row">
          <div v-if="props.showPicture" class="col-sm-4 img-responsive">
            <img v-if="props.lead.entity_data && props.lead.entity_data.picture" :src="props.lead.entity_data.picture" alt="Lead picture" />
            <img v-else src="~@/assets/img/property_dummy.gif" alt="Lead picture" />
          </div>

          <div class="col-sm-8">
            <div v-if="props.lead.next_activity_name">
              <strong>{{ props.lead.next_activity_name }}</strong>: {{ props.lead.next_activity_date | datetime }}
            </div>
            <div v-else class="label label-danger tw-mb-1">Geen activiteit</div>

            <div class="tw-my-2">
              <i class="fas fa-user tw-mb-1" />
              <template v-if="props.lead.candidate">
                <span class="tw-mx-1">{{ props.lead.candidate.name }}</span>
                <div
                  class="tw-inline-flex tw-mx-1 tw-font-semibold tw-text-white tw-text-[10px] tw-gap-x-1"
                >
                  <span class="tw-rounded tw-uppercase tw-px-1 tw-bg-success">{{ props.lead.language }}</span>
                  <span v-if="props.lead.candidate.contact_score" class="tw-rounded tw-uppercase tw-px-1 tw-bg-success">{{ props.lead.candidate.contact_score }}</span>
                </div>
                <component
                  :is="$options.components.ContactNotes"
                  :object="props.lead.candidate"
                  class="tw-inline-block"
                />
              </template>
            </div>

            <div class="label label-primary tw-mb-1">Bron: {{ props.lead.source_name}}</div>
          </div>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
import ContactNotes from '@/components/contacts/contact_render_components/ContactNotes'
import ContactScore from '@/components/contacts/ContactScore.vue'

export default {
  name: 'LeadCard',
  components: {
    ContactNotes,
    ContactScore
  },
  props: {
    lead: {
      type: Object,
      default () {
        return {}
      }
    },
    showPicture: {
      type: Boolean,
      default: true
    }
  }
}
</script>
