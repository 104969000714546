<template>
  <Page>
    <template #title>{{ viewConfig.name }}</template>
    <div slot="actionbar">
      <div class="btn-group margin-left-10">
        <label
          v-for="view in funnelViews"
          :key="view.id"
          :for="view.id"
          :title="view.title"
          :class="['btn', selectedFunnelView === view.value ? 'btn-primary' : 'btn-default']"
        >
          <input
            v-model="selectedFunnelView"
            :id="view.id"
            :name="view.id"
            :value="view.value"
            type="radio"
            class="tw-sr-only"
          />
          <i :class="['fas', view.icon]" />
        </label>
      </div>

      <div v-if="viewConfig.name === 'Dashboard'" class="btn-group margin-left-10">
        <label
          v-for="(toggle, label) in toggles"
          :key="toggle"
          :for="toggle"
          title="Toon of verberg intake leads"
          :class="['btn', $data[toggle] ? 'btn-primary' : 'btn-default']"
        >
          <input
            v-model="$data[toggle]"
            :id="toggle"
            :name="toggle"
            type="checkbox"
            class="tw-sr-only"
          />
          {{ label }}
        </label>
      </div>

      <div class="btn-group margin-left-10">
        <button
          title="Funnelinstellingen"
          :class="['btn btn-default', { filtersSelected }]"
          @click="showSettings"
        >
          <i class="fas fa-cog" />
        </button>
      </div>
    </div>
    <div class="tw-mx-2 tw-flex tw-flex-row tw-justify-between tw-gap-4 tw-items-center">
      <FormulateForm
        #default="{ isLoading }"
        name="query"
        class="tw-flex-grow tw-flex tw-flex-row tw-items-center tw-gap-2 tw-max-w-lg"
        @submit="setQueryValue"
      >
        <FormulateInput
          type="text"
          name="value"
          placeholder="Zoeken op lead, eigenaar of kandidaat"
          validation="min:3,length"
          :validation-messages="{
            min: 'Zoek op ten minste 3 karakters.'
          }"
          :input-class="['tw-m-0']"
          outer-class="tw-flex-grow"
        />

        <FormulateInput
          type="submit"
          :disabled="isLoading"
          outer-class="tw-m-0"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
            ]"
          />
          Zoeken
        </FormulateInput>
      </FormulateForm>

      <FormulateInput
        type="toggle"
        name="status"
        label="Long list"
        @input="status = $event ? 1 : 0"
      />
    </div>
    <!-- keep-alive to keep the components cached and prevent unnecessary re-rendering -->
    <keep-alive>
      <component
        v-if="collaborator"
        :is="selectedFunnelView"
        :columns="viewConfig.columns"
        :query="query"
        :status="status"
        class="tw-px-2.5"
      />
    </keep-alive>
    <FunnelSettingsModal ref="settingsModal" />
  </Page>
</template>

<script>
import { format } from 'date-fns'

import Page from '@/components/iam/Page'
import FunnelSettingsModal from '@/components/sales/FunnelSettingsModal'
import FunnelTableView from '@/components/sales/FunnelTableView'
import FunnelColumnView from '@/components/sales/FunnelColumnView'
import { createLog } from '@/services/apiService'

import { mapGetters } from 'vuex'

export default {
  name: 'Funnel',
  components: {
    Page,
    FunnelSettingsModal,
    FunnelTableView,
    FunnelColumnView
  },
  data () {
    return {
      funnelView: localStorage.getItem('iam-sales-new-funnel-view-mode'),
      includeIntake: JSON.parse(localStorage.getItem('iam-sales-funnel-include-intake') || 'true'),
      includeSales: JSON.parse(localStorage.getItem('iam-sales-funnel-include-sales') || 'true'),
      includeRent: JSON.parse(localStorage.getItem('iam-sales-funnel-include-rent') || 'true'),
      query: '',
      status: 0 // show short list by default, we use a string as default because HTML output is string.
    }
  },
  watch: {
    includeRent (value) {
      localStorage.setItem('iam-sales-funnel-include-rent', value)
    },
    includeSales (value) {
      localStorage.setItem('iam-sales-funnel-include-sales', value)
    },
    includeIntake (value) {
      localStorage.setItem('iam-sales-funnel-include-intake', value)
    }
  },
  computed: {
    ...mapGetters('sales', ['getFunnelFilters']),
    ...mapGetters('account', ['collaborator']),

    funnelViews () {
      return [
        { id: 'table', value: 'FunnelTableView', title: 'Toon in tabel', icon: 'fa-th-list' },
        { id: 'column', value: 'FunnelColumnView', title: 'Toon in kolommen', icon: 'fa-th-large' }
      ]
    },
    selectedFunnelView: {
      get () {
        return this.funnelView || 'FunnelColumnView'
      },
      set (value) {
        this.funnelView = value
        localStorage.setItem('iam-sales-new-funnel-view-mode', value)
        return value
      }
    },
    toggles () {
      return {
        IT: 'includeIntake',
        VK: 'includeSales',
        VH: 'includeRent'
      }
    },
    filtersSelected () {
      const { collaborator, office } = this.getFunnelFilters
      return Boolean(collaborator || office)
    },
    viewConfig () {
      const date = new Date()
      const dateFormat = "yyyy-MM-dd'T'HH:mm:ss" // The API doesn't support timezone aware dates

      date.setHours(0, 0, 0)
      const today_start = format(date, dateFormat)

      date.setHours(23, 59, 59)
      const today_end = format(date, dateFormat)

      const includedFunnels = new Set()
      if (this.includeIntake) includedFunnels.add(1)
      if (this.includeSales) includedFunnels.add(2)
      if (this.includeRent) includedFunnels.add(3)
      const funnels = Array.from(includedFunnels).join()

      const filters = { status: 0 } // status: 0 represents shortlisted leads
      const { collaborator, office } = this.getFunnelFilters
      if (office) filters.office = office.id
      filters.collaborator = collaborator?.id || this.collaborator?.id // Fallback to logged in collaborator

      const viewConfigs = [
        {
          name: 'Dashboard',
          columns: [
            {
              id: 'overdue',
              title: 'Over tijd',
              params: {
                funnels,
                next_activity_start_date_before: today_start,
                ...filters
              }
            },
            {
              id: 'today',
              title: 'Vandaag',
              params: {
                funnels,
                next_activity_start_date_after: today_start,
                next_activity_start_date_before: today_end,
                ...filters
              }
            },
            {
              id: 'future',
              title: 'Toekomst',
              params: {
                funnels,
                next_activity_start_date_after: today_end,
                ...filters
              }
            }
          ]
        },
        {
          name: 'Intakefunnel',
          columns: [
            { id: 1, title: 'Contact', params: { funnels: 1, stage: 1, ...filters } },
            { id: 2, title: 'Bezoek', params: { funnels: 1, stage: 2, ...filters } }
          ]
        },
        {
          name: 'Verkoopfunnel',
          columns: [
            { id: 4, title: 'Contact', params: { funnels: 2, stage: 4, ...filters } },
            { id: 5, title: 'Bezoek', params: { funnels: 2, stage: 5, ...filters } },
            { id: 6, title: 'Onderhandeling', params: { funnels: 2, stage: 6, ...filters } }
          ]
        },
        {
          name: 'Verhuurfunnel',
          columns: [
            { id: 7, title: 'Contact', params: { funnels: 3, stage: 7, ...filters } },
            { id: 8, title: 'Bezoek', params: { funnels: 3, stage: 8, ...filters } },
            { id: 9, title: 'Kandidatuur', params: { funnels: 3, stage: 9, ...filters } },
            { id: 10, title: 'Voorgesteld', params: { funnels: 3, stage: 10, ...filters } }
          ]
        }
      ]

      return viewConfigs[this.$route.query.vc || 0]
    }
  },
  methods: {
    showSettings () {
      this.$refs.settingsModal.show()
    },
    async setQueryValue ({ value }) {
      const payload = {
        action_id: 27, // Lead query action id
        meta: { value, source: 'dashboard' }
      }
      const response = await createLog(payload)
      this.query = value
      return response
    }
  }
}
</script>

<style scoped>
.filtersSelected {
  background-color: #FFD700;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    background-color: #FFD700;
  }
  50% {
    background-color: #FFA500;
  }

  100% {
    background-color: #FFD700;
  }
}
</style>
